@use "./functions" as *;

$base-font-family: Arial, Helvetica, sans-serif;

$header-font-family: 'Courier New', Courier, monospace;

// Colors

$primary: #2995D3;
$secondary: #1F2E77;
$accent:  #ffff99;
$dark: #1E1E1E;
$light: white;

$text-color: white;

// Sizes
$nav-width: 60px;

$default-font-size: pxToRem(20px);

$h1-font-size: pxToRem(60px);
$h2-font-size: pxToRem(48px);
$h3-font-size: pxToRem(36px);
$h4-font-size: pxToRem(32px);
$h5-font-size: pxToRem(29px);

$form-label-font-size: pxToRem(18px);
$form-input-font-size: pxToRem(24px);