button {
  border-radius: 4px;
  min-height: 40px;
  background-color: #2995D3;
  color: white;
  outline: none;
  border: none;
  font-weight: bold;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
  }
}