@use "styles" as *;

.post-form {
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-height: 100vh;

  &-form {
    display: flex;
    flex-flow: column nowrap;
    min-width: 50vw;
    min-height: 50vh;
  }

  label {
    font-size: pxToRem(18px);
    font-weight: bold;
  }
  
  input {
    min-height: 40px;
    border: none;
    padding: 2px 5px;
    font-size: pxToRem(36px);
    transition-duration: 200ms;
    background-color: rgba(41,149,211, .05);
    
    &:focus {
      outline: none;
    }
  }

  &-info-inputs {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
  }
  
  
  &-input-group {
    display: flex;
    flex-flow: column nowrap;
    border-bottom: 2px solid black;
    margin-bottom: 1rem;
  }

  &-body-input-group {
    width: 100%;
    
    textarea {
      width: 100%;
      min-width: 70vw;
      min-height: 50vh;
      text-align: left;
      background-color: rgba(41,149,211, .05);
      border: 2px solid black;
      font-size: pxToRem(28px);
      resize: none;
      padding: 1rem;

      &:focus {
        outline: none;
      }
    }
  }

  &-btn {
    &-group {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      gap: .5rem;

      button {
        transition-duration: 200ms;

        &:hover {
          background-color: $secondary;
        }
      }
    }

    &-delete {

    }

    &-ideas {

    }

    &-wip {

    }

    &-drafts {

    }

    &-stage {

    }
  }
}