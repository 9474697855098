@use "styles" as *;

.navbar {
  min-width: $nav-width;
  max-width: calc($nav-width + 1px);
  background-color: white;
  border-right: 1px solid black;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;

  .dark-mode {
    background-color: rgb(30,30,30);
  }
}

.nav {
  &-links {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    &-main {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 1rem;
    }

    &-settings {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-evenly;
      gap: 1rem;
      margin-bottom: 1rem;

      .toggle-dark-mode {
        &:hover {
          cursor: pointer;
        }
      }
    }


  }

  &-icon {
    transition-duration: 200ms;

    &:hover {
      transform: translateY(2px);
    }

    img {
      max-width: 40px;
    }
  }
}

