@use "styles" as *;

.tag {

  &-input {

    &-container {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 1rem;
    }

    &-form {
      border-bottom: 2px solid black;
      margin-right: 1rem;

      input {
        font-size: pxToRem(24px);
        background-color: rgba(41,149,211, .05)
      }
    }
  }

  &-list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-left: 1rem;
    gap: .5rem;
  }

  &-item {
    transition-duration: 500ms;
    padding: 4px 5px;
    background-color: $secondary;
    color: white;
    border-radius: 4px;
    margin: 1px;
    font-size: pxToRem(26px);
    font-weight: bold;
    height: fit-content;
    width: fit-content;

    &:hover {
      cursor: pointer;

    }
  }

  &-add-btn {
    background-color: rgba(120,120,120, .4);
    border-radius: 4px;
    border: none;
    color: white;
    font-weight: bold;
    font-size: pxToRem(40px);
    width: 35px;
    height: 35px;
    transition-duration: 300ms;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:hover {
      cursor: pointer;
      background-color: $primary;
    }
  }
}