@use "styles" as *;

.main-login {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100vh;
  background: url("~images/comic-bg-flat-light-blue.jpg") no-repeat center center;
  background-size: cover;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  

  &-wrapper {
    margin-top: 20vh; 
    padding: 3rem 10rem 4rem;
    border: 1px solid black;
    border-radius: 15px;
    background-color: white;
    width: fit-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transition-duration: 200ms;
  }

  &-form {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;

    &-group {
      display: flex;
      flex-flow: column nowrap;

      label {
        font-weight: bold;
        font-size: $form-label-font-size;
      }

      input {
        min-height: 35px;
        min-width: min(300px, 90vw);
        border-radius: 4px;
        border: 1px solid black;
        transition-duration: 300ms;
        font-size: pxToRem(20px);
        padding: 2px 10px;

        &:focus {
          outline: none;
          border: 1px solid $primary;
        }
      }
    }
  }

  &-btn-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    button {
      padding: 8px 40px;
      background-color: $primary;
      border: 1px solid black;
      border-radius: 4px;
      color: white;
      transition-duration: 300ms;

      &:hover {
        cursor: pointer;
        background-color: white;
        color: black;
      }
    }
  }
}